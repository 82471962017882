require('./_config')

global.jQuery = require('jquery')
global.$ = jQuery

const $ = require('../../node_modules/jquery/dist/jquery.min')
const mobileMenu = require('./_mobilemenu')
const tilt = require('./_tilt')
const slideshow = require('./_slideshow')
const popup = require('./_popup')
const search = require('./_search')

$(() => {
  mobileMenu.init()
  tilt.init()
  slideshow.init()
  popup.init()
  search.init()
})
