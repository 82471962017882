module.exports = {
  init: () => {
    $('.search-trigger').on('click', function () {
      $('.search').toggleClass('search--active')
      return false
    })
    $(document).on('click', function (e) {
      if ($(e.target).closest('.search').length === 0) {
        $('.search').removeClass('search--active')
      }
    })
  }
}
