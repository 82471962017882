require('../../node_modules/slick-slider/slick/slick.js')

module.exports = {
  init: () => {
    $('.slideshow').each((i, slider) => {
      const $slider = $(slider)
      $slider.slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: $slider
          .closest('.slideshow-wrapper')
          .find('.slideshow-controls-prev'),
        nextArrow: $slider
          .closest('.slideshow-wrapper')
          .find('.slideshow-controls-next'),
        responsive: [
          {
            breakpoint: 950,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      })
    })
  }
}
