require('../../node_modules/magnific-popup/dist/jquery.magnific-popup')

module.exports = {
  init: () => {
    $('.popup-trigger').magnificPopup({
      type: 'image',
      gallery: {
        enabled: true,
        preload: [0, 1],
        tCounter: '%curr% af %total%'
      },
      image: {
        cursor: null
      },
      zoom: {
        enabled: true,
        duration: 300, // don't foget to change the duration also in CSS
        opener: function (element) {
          return element.find('img')
        }
      }
    })
  }
}
